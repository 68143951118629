.main-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999; /* Lower than chat tabs to ensure they appear above */
  height: 64px; /* Fixed height to ensure chat tabs are positioned correctly */
}

.nav-menu {
  display: flex;
  justify-content: space-evenly !important;
  align-items: center;
  padding: 8px 0;
  border-bottom: none !important;
  width: 100%;
}

.nav-menu .ant-menu-item {
  margin: 0 !important;
  padding: 12px 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  width: 25% !important;
}

.nav-menu .ant-menu-item-selected {
  color: #6B4EFF !important;
  font-weight: bold;
}

.nav-menu .ant-menu-item-selected::after {
  display: none !important;
}

.nav-menu .ant-menu-item-active {
  color: #6B4EFF !important;
}

.nav-menu .anticon {
  font-size: 20px;
  margin-right: 0 !important;
  margin-bottom: 4px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.nav-menu .ant-menu-title-content {
  font-size: 12px;
  width: 100%;
  text-align: center;
  display: block;
}

.nav-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.nav-item-content .nav-icon {
  font-size: 20px;
  margin-bottom: 4px;
}

.nav-item-content a {
  text-align: center;
  display: block;
  font-size: 12px;
  line-height: 1;
}

/* Fix for Ant Design menu items */
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border-bottom: none !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-menu-horizontal > .ant-menu-item {
  position: relative;
  top: 0;
  display: inline-block;
  vertical-align: bottom;
}

/* Chat floating button styles */
.chat-float-button {
  z-index: 990 !important;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.2), 0 9px 28px 0 rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.chat-float-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px -8px rgba(0, 0, 0, 0.3), 0 12px 32px 0 rgba(0, 0, 0, 0.15) !important;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .nav-menu .ant-menu-item {
    padding: 8px 0 !important;
  }
  
  .nav-menu .anticon,
  .nav-item-content .nav-icon {
    font-size: 18px;
  }
  
  .nav-menu .ant-menu-title-content,
  .nav-item-content a {
    font-size: 10px;
  }
  
  .nav-item-content {
    padding-bottom: 4px;
  }
  
  .chat-float-button {
    right: 16px !important;
    bottom: 72px !important;
    width: 42px !important;
    height: 42px !important;
  }
}