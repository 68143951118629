/* Plans container */
.plans-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px 16px;
  margin-bottom: 5px;
}

/* Baby profile header */
.baby-profile-header {
  margin-bottom: 20px;
  padding: 16px 20px;
  background: linear-gradient(135deg, #f0f7ff 0%, #e8f0fd 100%);
  border-radius: 16px;
  box-shadow: 0 3px 10px rgba(76, 111, 255, 0.08);
  border: 1px solid #e6ecff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.baby-profile-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
  background-image: url('../../assets/images/BabyShower.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  opacity: 0.15;
  z-index: 0;
}

.baby-profile-info {
  display: flex;
  align-items: center;
  z-index: 1;
}

.baby-photo-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  border: 3px solid #fff;
  box-shadow: 0 4px 10px rgba(76, 111, 255, 0.15);
  background: #fff;
}

.baby-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.baby-details {
  display: flex;
  flex-direction: column;
}

.baby-stats {
  display: flex;
  margin-top: 4px;
  font-size: 14px;
  color: #5a73b2;
}

.baby-stat-item {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

.baby-stat-label {
  font-weight: 500;
  margin-right: 4px;
}

.baby-stat-value {
  font-weight: 600;
  color: #5c9dff;
}

.baby-journey-phase {
  z-index: 1;
}

.journey-phase-badge {
  background: #5c9dff;
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(92, 157, 255, 0.3);
}

/* Progress overview */
.progress-overview {
  margin-bottom: 16px;
}

.progress-card {
  border-radius: 16px;
  box-shadow: 0 3px 10px rgba(76, 111, 255, 0.08);
  border: 1px solid #e6ecff;
  background-color: #fff;
  padding: 16px;
}

.journey-context {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #e6ecff;
}

.next-milestone, .upcoming-appointment {
  display: flex;
  flex-direction: column;
}

.milestone-label, .appointment-label {
  font-size: 12px;
  color: #5a73b2;
  margin-bottom: 2px;
  font-weight: 500;
}

.milestone-value, .appointment-value {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.milestone-when, .appointment-when {
  font-size: 12px;
  color: #5c9dff;
  margin-left: 4px;
  font-weight: normal;
}

.stat-compact {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 13px;
  color: #5a73b2;
}

/* Plans list */
.plans-list {
  margin-bottom: 20px;
}

.plan-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.section-title-with-icon {
  display: flex;
  align-items: center;
}

.section-icon {
  font-size: 18px;
  margin-right: 8px;
  font-style: normal;
}

.plan-card {
  margin-bottom: 14px !important;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(76, 111, 255, 0.07);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #edf0ff;
  background-color: #fff;
}

.plan-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(76, 111, 255, 0.12);
  border-color: #dce4ff;
}

.plan-content {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
}

.plan-info {
  flex: 1;
  margin-right: 12px;
}

.plan-meta {
  margin-top: 10px;
}

.plan-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
}

.plan-stats-group {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.plan-progress-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.plan-progress-text {
  min-width: 80px;
  font-size: 12px !important;
  margin-right: 8px;
  white-space: nowrap;
}

.plan-progress-mini {
  flex: 1;
  height: 8px;
  background-color: #eff2ff;
  border-radius: 4px;
  overflow: hidden;
}

.plan-progress-mini-bar {
  height: 100%;
  transition: width 0.4s ease;
}

.plan-progress-high {
  background-color: #78c6ff; /* Light blue */
  background-image: linear-gradient(to right, #78c6ff, #5c9dff);
}

.plan-progress-medium {
  background-color: #94c9ff; /* Soft blue */
  background-image: linear-gradient(to right, #94c9ff, #70b5ff);
}

.plan-progress-low {
  background-color: #b4d4ff; /* Lighter blue */
  background-image: linear-gradient(to right, #b4d4ff, #9ac2ff);
}

.plan-stats-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 4px;
}

.plan-stat-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.plan-stat-icon-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.plan-actions {
  display: flex;
  align-items: center;
}

.plan-delete-button {
  margin-right: 12px;
  color: #ff4d4f;
  font-size: 16px;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.plan-delete-button:hover {
  opacity: 1;
}

/* Empty state */
.empty-plans {
  text-align: center;
  padding: 36px 20px;
  background-color: #f4f7ff;
  border-radius: 16px;
  margin-bottom: 20px;
  border: 1px dashed #d0dcff;
}

.empty-plans-title {
  margin-bottom: 8px !important;
  color: #4a73c0;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.empty-plans-description {
  color: #7082b2;
  max-width: 320px;
  margin: 0 auto !important;
  font-size: 14px;
  line-height: 1.4;
}

/* Milestones section */
.milestones-section {
  margin-bottom: 24px;
}

.milestones-timeline {
  position: relative;
  padding-left: 20px;
  margin-top: 16px;
}

.milestones-timeline::before {
  content: '';
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #e6ecff;
  z-index: 0;
}

.milestone-item {
  position: relative;
  margin-bottom: 16px;
  display: flex;
}

.milestone-marker {
  position: absolute;
  left: -20px;
  top: 10px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #9ac2ff;
  z-index: 1;
}

.milestone-completed .milestone-marker {
  background: #5c9dff;
  border-color: #5c9dff;
}

.milestone-current .milestone-marker {
  background: #fff;
  border-color: #5c9dff;
  box-shadow: 0 0 0 4px rgba(92, 157, 255, 0.15);
}

.milestone-upcoming .milestone-marker {
  background: #fff;
  border-color: #b4d4ff;
}

.milestone-content {
  background: #fff;
  border-radius: 12px;
  padding: 12px 16px;
  border: 1px solid #e6ecff;
  box-shadow: 0 2px 8px rgba(76, 111, 255, 0.05);
  width: 100%;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.milestone-title {
  font-weight: 600;
  font-size: 15px;
  color: #333;
}

.milestone-date {
  font-size: 12px;
  color: #5a73b2;
}

.milestone-note {
  font-size: 13px;
  color: #666;
}

.milestone-completed .milestone-content {
  border-left: 3px solid #5c9dff;
}

.milestone-current .milestone-content {
  border-left: 3px solid #70b5ff;
  background-color: #f8faff;
}

.milestone-upcoming .milestone-content {
  border-left: 3px solid #9ac2ff;
  opacity: 0.8;
}

/* Suggested Plans */
.suggested-plans-section {
  margin-bottom: 24px;
}

.suggested-plan-card {
  margin-bottom: 14px !important;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(76, 111, 255, 0.05);
  background-color: #f8faff;
  border: 1px solid #e6ecff;
  transition: all 0.3s ease;
}

.suggested-plan-card.accepted {
  background-color: #fff;
  cursor: pointer;
}

.suggested-plan-card.accepted:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(76, 111, 255, 0.1);
  border-color: #d0dcff;
}

.suggested-plan-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

/* Carousel Styles */
.carousel-container {
  margin-top: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #d0dcff transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.carousel-container::-webkit-scrollbar {
  height: 6px;
}

/* Track */
.carousel-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.carousel-container::-webkit-scrollbar-thumb {
  background: #d0dcff;
  border-radius: 10px;
}

/* Handle on hover */
.carousel-container::-webkit-scrollbar-thumb:hover {
  background: #5c9dff;
}

.recommended-lists-section {
  margin-top: 24px;
  margin-bottom: 20px;
}

.update-profile-link {
  text-decoration: underline;
  cursor: pointer;
  display: block;
  margin-top: 4px;
  font-size: 11px;
}

/* Template selection */
.template-selection {
  padding: 16px 0;
}

.template-card {
  position: relative;
  border: 1px solid #f0f0f0;
  transition: all 0.2s;
  height: 100%;
}

.template-card:hover {
  border-color: #1890ff;
}

.template-card.selected {
  border-color: #1890ff;
  background-color: #e6f7ff;
}

.template-radio {
  position: absolute;
  top: 12px;
  right: 12px;
}

.template-content {
  padding: 8px 0;
}

.template-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 8px;
}

.build-from-scratch {
  text-align: center;
  margin-top: 24px;
}

/* Loading state */
.loading-container {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #5a73b2;
  background-color: #f8faff;
  border-radius: 16px;
  margin-bottom: 16px;
  border: 1px dashed #d0dcff;
}

/* Mobile responsive */
@media (max-width: 768px) {
  .plan-content {
    flex-direction: column;
    padding: 16px;
  }
  
  .plan-info {
    margin-right: 0;
    margin-bottom: 16px;
  }
  
  .plan-actions {
    align-self: flex-end;
  }
  
  .suggested-plan-actions {
    flex-direction: row;
    margin-top: 16px;
  }
}