/* Main container */
.categories-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

/* New UI for products by topics & categories */
.topics-categories-products-container {
  margin: 20px;
}

.topic-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Ensure topic menu has proper width and prevents text from being cut off */
.ant-menu-vertical > .ant-menu-item {
  height: auto !important;
  line-height: 1.3 !important;
  white-space: normal !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
  margin-bottom: 2px !important;
  margin-top: 0 !important;
}

/* Ensure the ant-menu doesn't restrict item height */
.ant-menu-item {
  min-height: 32px;
}

/* Prevent text from being cut off */
.topic-name {
  word-wrap: break-word;
  word-break: break-word;
  padding-right: 8px;
}

/* Improve menu appearance */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f7ff !important;
  border-radius: 4px;
}

/* Add some hover effect */
.ant-menu-item:hover {
  background-color: #f5f5f5 !important;
  border-radius: 4px;
}

.product-grid {
  margin-top: 20px;
}

.load-more-container {
  text-align: center;
  margin: 24px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
}

/* Topic styles */
.topic-container {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.topic-header {
  padding: 15px 20px;
  font-size: 24px;
  font-weight: 500;
  color: #333;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.categories-list {
  padding: 20px;
}

/* Category item styles */
.category-item {
  margin-bottom: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.category-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.category-header {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}

.category-name {
  flex-grow: 1;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.remove-category-button {
  margin-right: 10px;
}

/* Expanded category styles */
.category-item.expanded .category-header {
  background-color: #white;
}

/* Product list styles */
.product-list {
  padding: 15px;
}

/* Empty state styles */
.ant-empty {
  padding: 40px 0;
}

.product-category-text {
  color: #1890ff;
  text-decoration: none;
}

.product-category-text:hover {
  text-decoration: underline;
}

/* Checkbox styles */
.ant-checkbox-wrapper {
  font-size: 16px;
}

/* Button styles */
.ant-btn {
  font-size: 14px;
}

/* Rotating background colors for topics */
.topic-container:nth-child(6n+1) { background-color: #f0f5ff; }
.topic-container:nth-child(6n+2) { background-color: #f5f0ff; }
.topic-container:nth-child(6n+3) { background-color: #fff5f0; }
.topic-container:nth-child(6n+4) { background-color: #f0fff5; }
.topic-container:nth-child(6n+5) { background-color: #fff0f5; }
.topic-container:nth-child(6n) { background-color: #f5fff0; }

/* Responsive styles */
@media (max-width: 768px) {
  .categories-container {
    padding: 10px;
  }

  .topic-header {
    font-size: 20px;
    padding: 10px 15px;
  }

  .categories-list {
    padding: 15px;
  }

  .category-header {
    padding: 10px;
  }

  .category-name {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .topic-header {
    font-size: 18px;
  }

  .category-name {
    font-size: 13px;
  }

  .ant-checkbox-wrapper,
  .ant-btn {
    font-size: 12px;
  }
}

/* Ensure content is visible under fixed header/footer if present */
.categories-container {
  padding-top: 60px; /* Adjust based on your header height */
  padding-bottom: 60px; /* Adjust based on your footer height */
}

/* If you have a sticky filter, add this */
.filter-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  padding: 10px 0;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* #components-transfer-demo-table-transfer .ant-table td {
  background: transparent;
} */
