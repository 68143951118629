/* productChatbot.css */

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 50vh;  /* Changed from 200px to 50vh */
  max-height: 600px; /* Added max-height as a safeguard */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  overflow: hidden;
}

.chatbot-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.message {
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 12px;
  margin: 4px 0;
}

.message.user {
  background-color: #007AFF;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 4px;
}

.message.bot {
  background-color: #f0f0f0;
  color: #333;
  align-self: flex-start;
  border-bottom-left-radius: 4px;
}

.message.understanding {
  background-color: #E8F5E9;
  font-style: italic;
}

.message.transition {
  background-color: #E3F2FD;
  width: 100%;
  max-width: 100%;
  text-align: center;
  border-radius: 4px;
}

.message-content {
  margin-bottom: 8px;
  line-height: 1.4;
}

.educational-tip {
  margin-top: 8px;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 3px solid #4CAF50;
  font-size: 0.9em;
  border-radius: 4px;
}

.bot .educational-tip {
  background-color: rgba(0, 0, 0, 0.05);
}

.impacts {
  margin-top: 12px;
  padding: 8px;
  background-color: rgba(33, 150, 243, 0.1);
  border-radius: 4px;
}

.impacts h4 {
  margin: 0 0 8px 0;
  font-size: 0.9em;
  color: #1976D2;
}

.impact-item {
  margin: 4px 0;
  font-size: 0.9em;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.clarification-note {
  margin-top: 8px;
  font-size: 0.9em;
  color: #F57C00;
  font-style: italic;
}

.typing-indicator {
  padding: 12px 16px;
  background-color: #f0f0f0;
  border-radius: 12px;
  align-self: flex-start;
  color: #666;
  font-size: 0.9em;
  animation: pulse 1.5s infinite;
}

.chatbot-input {
  display: flex;
  padding: 16px;
  background-color: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  gap: 8px;
}

.chatbot-input input {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  outline: none;
  font-size: 1em;
}

.chatbot-input input:focus {
  border-color: #007AFF;
  box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.1);
}

.chatbot-input button {
  padding: 12px 24px;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.chatbot-input button:hover {
  background-color: #0056b3;
}

.chatbot-input button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.tip-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #4CAF50;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}

@keyframes pulse {
  0% { opacity: .5; }
  50% { opacity: 1; }
  100% { opacity: .5; }
}

/* Scrollbar Styling */
.chatbot-messages::-webkit-scrollbar {
  width: 6px;
}

.chatbot-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chatbot-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .chatbot-container {
    height: 40vh; /* Changed from 100vh to 60vh */
    border: none;
    border-radius: 0;
  }

  .message {
    max-width: 90%;
  }

  .chatbot-input {
    padding: 12px;
  }

  .chatbot-input button {
    padding: 12px 20px;
  }
}
