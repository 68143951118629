/* Explore container */
.explore-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px 16px;
  margin-bottom: 5px;
}

/* Explore search section */
.explore-search-section {
  margin-bottom: 16px;
}

.search-filter-container {
  display: flex;
  width: 100%;
}

.explore-search {
  width: 100%;
  max-width: 100%;
}

/* Search field in filter */
.explore-filters .explore-search {
  border-radius: 16px;
  overflow: hidden;
}

.explore-filters .explore-search .ant-input {
  border-radius: 16px 0 0 16px;
  height: 28px;
}

.explore-filters .explore-search .ant-input-search-button {
  height: 28px;
  border-radius: 0 16px 16px 0;
}

/* Filters */
.explore-filters {
  position: fixed;
  top: 70px; /* Positioned below the header */
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 57px); /* Reduced width as requested */
  max-width: 800px;
  margin-bottom: 20px;
  padding: 0 16px 0; /* No padding top or bottom */
  margin-left: auto;
  margin-right: auto; /* Ensure centered with equal spacing on both sides */
  background: #f8faff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(76, 111, 255, 0.1);
  border: 1px solid #e6ecff;
  z-index: 40;
  transition: height 0.25s ease-out; /* Only transition height */
  overflow: hidden; /* Hide overflow during transition */
  transform-origin: top center; /* Set transform origin to top */
}

.filter-section {
  margin-bottom: 3px; /* Changed to exactly 3px as requested */
  margin-top: 3px; /* Added consistent top margin of 3px */
}

.filter-section:last-child {
  margin-bottom: 0;
}

.filter-section:first-child {
  margin-top: 3px; /* Changed to exactly 3px as requested */
  padding-top: 0; /* No top padding for first section */
}

/* Title margins in filter sections */
.explore-filters .ant-typography {
  margin-top: 3px; /* Added 3px margin top as requested */
  margin-bottom: 0 !important; /* Removed bottom margin as requested */
}

/* Add fade effect for specified sections */
.fade-section {
  opacity: var(--fade-opacity, 1);
  transition: opacity 0.2s ease-out;
  position: relative;
}

/* Keep view and search visible */
.remain-visible {
  opacity: 1 !important;
}

/* Filter layout */
.explore-filters {
  display: flex;
  flex-direction: column;
}

/* View options */
.filter-view-options {
  display: flex;
  gap: 12px;
  margin-top: 0;
}

/* Removed the hide-in-compact class as we're using fade-section for everything */

.filter-tags {
  margin-top: 0; /* Removed margin-top as requested */
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px; /* Keep the spacing between tags */
  overflow: visible;
}

/* View filter styles to match other filters */
.filter-view-options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px; /* Keep the gap between options */
  margin-top: 0; /* Changed to zero margin as requested */
}

.filter-view-options .ant-tag {
  margin-right: 0;
  padding: 4px 10px;
  border-radius: 16px;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filter-view-options .ant-tag.checked {
  background-color: #1890ff;
  color: white;
  border-color: #1890ff;
}

.filter-active {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.filter-placeholder {
  width: 100%;
  transition: none; /* Match the filter container - no transition */
  margin-bottom: 0; /* Let the content container handle spacing */
  visibility: hidden; /* Make placeholder invisible */
}

/* Creator tags */
.creator-tag {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 4px;
}

.creator-official {
  background-color: #e6f7ff;
  color: #1890ff;
}

.creator-friend {
  background-color: #f6ffed;
  color: #52c41a;
}

.creator-public {
  background-color: #f9f9f9;
  color: #8c8c8c;
}

/* Explore cards */
.explore-card {
  height: 100%;
  margin-bottom: 0;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 10px rgba(76, 111, 255, 0.07);
  border: 1px solid #edf0ff;
  display: flex;
  flex-direction: column;
}

.explore-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(76, 111, 255, 0.12);
  border-color: #dce4ff;
}

.explore-card-content {
  display: flex;
  padding: 16px;
  flex: 1;
}

.explore-card-image {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
  background: #f5f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.explore-card-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.explore-card-meta {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.explore-card-categories {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.explore-card-actions {
  padding: 0 16px 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

/* Add custom button */
.add-custom-button {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

/* Previously viewed */
.previously-viewed {
  margin-bottom: 20px;
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  gap: 12px;
  scrollbar-width: thin;
  scrollbar-color: #bfdbfe #f0f7ff;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 6px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background: #f0f7ff;
  border-radius: 10px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #bfdbfe;
  border-radius: 10px;
}

.mini-product-card {
  min-width: 120px;
  width: 120px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(76, 111, 255, 0.06);
  background: white;
  border: 1px solid #edf0ff;
  margin-bottom: 0;
}

.mini-product-card img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-bottom: 1px solid #f0f5ff;
}

.mini-product-card .ant-typography {
  padding: 6px 8px;
  margin: 0;
  font-size: 12px;
  text-align: center;
}

/* Current needs tags */
.current-needs-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* Tabs */
.explore-tabs {
  margin-top: 24px;
}

.explore-tabs .ant-tabs-nav {
  margin-bottom: 24px;
}

.explore-tabs .ant-tabs-tab {
  padding: 8px 16px;
  font-size: 14px;
}

.explore-tabs .ant-tabs-ink-bar {
  background-color: #5c9dff;
}

/* Fix journey phase badge */
.baby-journey-phase .journey-phase-badge {
  white-space: nowrap;
}

/* Fix List grid spacing */
.ant-list-grid .ant-list-item {
  margin-bottom: 24px;
  padding: 10px;
  background-color: transparent !important;
}

/* Create visual space by adding margin to each row */
.ant-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Add spacing to each card within the grid */
.ant-col {
  padding: 10px !important;
}

/* Ensure cards don't touch within the grid */
.ant-list-grid .ant-col > div {
  margin: 5px;
  height: calc(100% - 10px);
}

/* Mobile responsive */
@media (max-width: 768px) {
  .explore-card-content {
    flex-direction: column;
  }
  
  .explore-card-image {
    width: 100%;
    height: 160px;
    margin-right: 0;
    margin-bottom: 12px;
  }
  
  .explore-search-section .plan-title-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .explore-search-section .plan-title-section > div:last-child {
    margin-top: 12px;
    width: 100%;
  }
  
  .search-filter-container {
    width: 100%;
  }
  
  .explore-search {
    flex: 1;
  }
}
