/* Profile container */
.profile-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px 16px;
  margin-bottom: 5px;
}

.profile-section {
  margin-bottom: 20px;
}

.profile-info-card {
  border-radius: 16px;
  box-shadow: 0 3px 10px rgba(76, 111, 255, 0.08);
  border: 1px solid #e6ecff;
  margin-bottom: 20px;
  overflow: visible;
}

/* Profile header */
.baby-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 20px;
  background: linear-gradient(135deg, #f0f7ff 0%, #e8f0fd 100%);
  border-radius: 16px;
  box-shadow: 0 3px 10px rgba(76, 111, 255, 0.08);
  border: 1px solid #e6ecff;
  margin-bottom: 20px;
}

.profile-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Baby photo container */
.baby-photo-container {
  width: 60px \!important;
  height: 60px \!important;
  border-radius: 50% \!important;
  overflow: hidden;
  margin-right: 16px;
  border: 3px solid #fff;
  box-shadow: 0 4px 10px rgba(76, 111, 255, 0.15);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.baby-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Stats row */
.stats-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

/* Profile name container */
.profile-name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.edit-button {
  font-size: 14px;
  padding: 0;
}

/* Journey phase badge */
.baby-journey-phase .journey-phase-badge {
  background: #5c9dff;
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(92, 157, 255, 0.3);
  white-space: nowrap;
}

/* Profile announced */
.profile-announced {
  margin-top: 16px;
  padding: 16px;
  background-color: #f8f9ff;
  border-radius: 10px;
  border: 1px dashed #d9e0ff;
}

.announced-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.announced-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.announced-icon {
  margin-left: 8px;
  font-size: 16px;
}

.public {
  color: #52c41a;
}

.private {
  color: #ff4d4f;
}

.announced-description {
  font-size: 12px;
}

/* Profile about */
.profile-about {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 16px;
}

/* Profile connections */
.profile-connections {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #f0f0f0;
  padding-top: 16px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.connection-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connection-count {
  font-size: 20px;
  font-weight: 600;
  color: #5c9dff;
}

.connection-label {
  font-size: 12px;
  color: #5a73b2;
}

/* Profile achievements */
.achievement-card {
  border-radius: 10px;
  margin-bottom: 10px;
}

.achievement-content {
  display: flex;
  align-items: center;
}

.achievement-icon {
  font-size: 24px;
  color: #faad14;
  margin-right: 16px;
}

.achievement-details {
  flex: 1;
}

.achievement-stats {
  margin-top: 8px;
}

/* Profile actions */
.profile-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
}

/* Lists */
.list-card {
  border-radius: 10px;
  margin-bottom: 10px;
}

.list-card-content {
  display: flex;
  align-items: center;
}

.list-creator-avatar {
  margin-right: 12px;
}

.list-details {
  flex: 1;
}

.list-meta {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  font-size: 12px;
}

.list-tags {
  margin-top: 8px;
}

/* Person card */
.person-card {
  border-radius: 10px;
  margin-bottom: 10px;
}

.person-card-content {
  display: flex;
  align-items: center;
}

.person-details {
  margin-left: 16px;
  flex: 1;
}

/* Add button container */
.add-button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Creator tags */
.creator-tag {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 4px;
}

.creator-official {
  background-color: #e6f7ff;
  color: #1890ff;
}

.creator-friend {
  background-color: #f6ffed;
  color: #52c41a;
}

.creator-public {
  background-color: #f9f9f9;
  color: #8c8c8c;
}

/* Specific profile styles */
.profile-tabs-section .ant-tabs-nav {
  margin-bottom: 16px;
}

.profile-tabs-section .ant-tabs-tab {
  padding: 8px 16px;
  font-size: 14px;
}

.profile-tabs-section .ant-tabs-ink-bar {
  background-color: #5c9dff;
}

/* Mobile responsive */
@media (max-width: 768px) {
  .baby-profile-header {
    flex-direction: column;
    padding-top: 36px; /* Make room for the edit button */
  }
  
  .edit-button {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  
  .profile-name-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .edit-button {
    margin-top: 4px;
  }
  
  .profile-meta-item {
    flex-direction: column;
  }
  
  .profile-connections {
    padding: 16px;
  }
  
  .achievement-content,
  .list-card-content,
  .person-card-content {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .achievement-icon,
  .list-creator-avatar {
    margin-bottom: 12px;
  }
  
  .person-details {
    margin-left: 0;
    margin-top: 12px;
  }
}
